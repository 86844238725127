import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
} from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Locale } from 'date-fns';
import {
    enUS,
    fr,
} from 'date-fns/locale';
import {
    BehaviorSubject,
    noop,
} from 'rxjs';

type ShouldReuseRoute = (future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) => boolean;

@Injectable({
    providedIn: 'root',
})
export class LocaleService {
    public currentDateFnsAdapterLocale$ = new BehaviorSubject<Locale>(this.currentDateFnsAdapterLocale);

    private initialized = false;

    get currentLocale(): string {
        return this.translate.getActiveLang();
    }

    get currentDateFnsAdapterLocale(): Locale {
        switch (this.translate.getActiveLang()) {
            case 'en':
                return enUS;
            default:
                return fr;
        }
    }

    private shouldReuseRoute!: ShouldReuseRoute;

    constructor(
        private router: Router,
        private translate: TranslocoService,
    ) {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.shouldReuseRoute = this.router.routeReuseStrategy.shouldReuseRoute;
    }

    public async initLocale(localeId: string): Promise<void> {
        if (this.initialized) return;

        await this.setLocale(localeId);

        this.initialized = true;
    }

    public async setLocale(localeId: string) {
        if (this.currentLocale === localeId) {
            return;
        }

        this.translate.setActiveLang(localeId);

        switch (localeId) {
            case 'en':
                this.currentDateFnsAdapterLocale$.next(enUS);
                break;
            default:
                this.currentDateFnsAdapterLocale$.next(fr);
                break;
        }

        await this.forceRouterReload();
    }

    private setRouteReuse(reuse: ShouldReuseRoute) {
        this.router.routeReuseStrategy.shouldReuseRoute = reuse;
    }

    private async forceRouterReload() {
        if (!this.initialized) {
            return;
        }

        this.setRouteReuse(() => false);
        this.router.navigated = false;

        await this.router.navigateByUrl(this.router.url).catch(noop);
        this.setRouteReuse(this.shouldReuseRoute);
    }
}
